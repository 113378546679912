<template>
  <v-row class="main-bg-rebel">
    <v-col cols="12" sm="10" md="8" lg="6" class="mt-5 mx-auto">
      <v-card elevation="2" class="mt-5 px-3">
        <v-col cols="12">
          <v-card-title>Contact</v-card-title>
          <v-card-subtitle>Envoyer un message</v-card-subtitle>

          <v-divider class="mb-5"/>

          <template v-if="!emailSent">
            <!-- <v-card class="pa-5" color="" > -->
            <v-form ref="formContact">
              <v-text-field v-model="message.name"    :rules="[rules.requiredName]"     outlined label="Votre nom"></v-text-field>
              <v-text-field v-model="message.email"   :rules="[rules.requiredEmail]"    outlined label="Votre adresse e-mail"></v-text-field>
              <v-textarea   v-model="message.message" :rules="[rules.requiredMessage]"  outlined label="Votre message..." rows="10"></v-textarea>
            </v-form>

            <v-row class="pb-3">
              <v-toolbar dense color="transparent" elevation="0">
                <v-spacer/>
                <v-btn color="blue" class="px-4" large rounded dark @click="showDialogCaptcha()">
                  <v-icon class="mr-1">mdi-mail</v-icon> Envoyer
                </v-btn>
              </v-toolbar>
            </v-row>
            <!-- </v-card> -->
          </template>
          <template v-else>
            <v-card class="text-center pa-5" elevation="0" outlined>
              <v-icon class="mr-1">mdi-check</v-icon> Votre message a bien été envoyé !<br>
              <v-btn class="my-2 mx-auto" large color="blue" dark to="/shop">
                <b>Retourner vers la boutique</b>
                <v-icon class="mr-1">mdi-arrow-right</v-icon> 
              </v-btn>
            </v-card>
          </template>
        </v-col>
      </v-card>
      <br><br><br><br><br>
    </v-col>

    <DialogCaptcha :actionSuccess="sendMessage"/>
  </v-row>
</template>


<script>
//import core from '../../plugins/core.js'
import axios from "axios"
//import router from "@/router/router"

import DialogCaptcha from '@/components/main-layout/DialogCaptcha.vue'

export default {
    name: "dialog-product",
    components: {
      DialogCaptcha
    },
    props: ['product'],
    data: function () {
        return {
          emailSent: false,
          message : {
            name: '',
            email: '',
            message: ''
          },
          rules: {
            requiredName: value => !!value || "Merci d'indiquer votre nom",
            requiredEmail: value => !!value || "Merci d'indiquer votre adresse e-mail",
            requiredMessage: value => !!value || "Message vide",
          }
        };
    },
    mounted: function(){
        this.$store.dispatch('app/incStat', '/contact')
    },
    methods: {
      showDialogCaptcha(){
        if(this.$refs.formContact.validate())
        this.$store.dispatch('captcha/setShowDialog', true)
      },
        async sendMessage(answerCaptcha){
          const { data } = await axios.post('/admin/send-mail-contact',
                                         { message: this.message,
                                           captcha: {
                                             answer: answerCaptcha,
                                             sessionid: this.$store.state.captcha.session.sessionid
                                           } })
          if(data.error == false){
            this.emailSent = true
          }else{
            this.emailSent = false

          this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'red', 
                                  title: "Une erreur est survenue pendant l'envoi de votre message",
                                  text: "Merci de réessayer ultérieurement",
                                  icon: 'alert' })
          }
        },
    },
    computed:{
    }
};
</script>